const getPalette = (theme) => {
  return {
    type: "light",
    primary: { main: "#f2ce00", hover: "#F9E780", contrastText: "#000" },
    secondary: { main: "#493e07", hover: "#A49E80" },
    accent: "#493e07",
    info: {
      main: "#493e07",
    },
    error: {
      main: "#FF0040",
    },
    white: {
      main: "#493e07",
    },
  };
};

export default getPalette;
