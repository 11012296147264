import React from "react";
import Typography from "@mui/material/Typography";

import cssStyles from "./About.module.scss";

export function getAboutText() {
  return (
    <div className={cssStyles.about}>
      <Typography variant="body1" paragraph>
        Welcome to Eagles Clubhouse where you can enjoy our pizza and fried
        chicken. Be our family members and enjoy the best pizza and fresh fried
        chicken, spicy chicken, fries, chicken popcorn and much more. All fresh,
        cooked to order.
      </Typography>
      <Typography variant="body1" paragraph>
        From family nights out, to going out with your friends, Eagles clubhouse
        is a perfect place to enjoy the best pizza and fried chicken.
      </Typography>
      <Typography variant="body1" paragraph>
        We're built upon traditional principles and commitment to quality,
        services, and long-standing relationships with communities and
        employees.
      </Typography>
      <Typography variant="body1" paragraph>
        Eagles Clubhouse is located at the beautiful cricket and football ground
        on Cherry Lane, Langley Green, Crawley, RH11 7NX. Here you can enjoy a
        great meal with family and friends in a clean, comfortable atmosphere,
        and for a reasonable price. The only place where you can beat the
        inflation!
      </Typography>
      <Typography variant="body1">
        Thank you for choosing Eagles Clubhouse.
      </Typography>
    </div>
  );
}

export function getAdditionalAboutText() {
  return null;
}

export function getAccolades() {
  return [];
}

export function getGalleryMenuImages() {
  return [];
}

export function getGalleryRestaurantImages() {
  return [];
}
